@font-face {
  font-family: JOSTLIGHT;
  src: url("./fonts/jost-light.woff2") format("woff2");
}

@font-face {
  font-family: Renner;
  src: url("./fonts/Renner-it-Medium.woff2") format("woff2");
}

/* @font-face {
  font-family: FelixTitling;
  src: url("./fonts/Renner_ 400 Book.ttf");
} */

body {
  overflow-x: hidden;
  font-family: "Renner" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: JOSTLIGHT, Helvetica, sans-serif;
  /* font-family: "Jost", sans-serif; */
  /* font-weight: 300; */
}

.heading {
  font-family: JOSTLIGHT, Helvetica, sans-serif !important;
  font-size: 2.375rem !important;
}

main {
  background: #f9f9f9;
  padding-bottom: 5px !important;
  font-family: "Renner" !important;
}

.logout {
  cursor: pointer;
}

.carousel-wrapper {
  position: relative;
  transition: "all";
}

.carousel-header {
  display: flex;
  justify-content: center; /* Center the text */
  align-items: center;
  position: relative;
  margin-bottom: 1rem; /* Adjust as needed */
}

.carousel-header .heading {
  flex-grow: 1; /* Allow the heading to take up available space */
  text-align: center; /* Center the text within the heading */
}

/* Add responsiveness */
@media (max-width: 768px) {
  .carousel-header {
    width: 100% !important;
    flex-direction: column;
    display: flex;
    position: relative;
    justify-content: end;
    align-items: end;
    place-items: end;
  }

  .subscribe-sign-in-mobile {
    display: none;
  }
}

@media (min-width: 769px) {
  .carousel-header {
    flex-direction: row;
  }
}

/* search bar */
.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ccc;
  position: absolute;
  top: 60px; /* Adjust based on your header height */
  left: 0;
  right: 0;
  z-index: 100;
}

.search-input {
  width: 80%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
}

.mx-auto{
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.search-button {
  padding: 12px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #444;
}

/* add mobile media query */
@media (max-width: 768px) {
  .header .container:nth-child(n+2), .header .container-fluid:nth-child(n+2), .header .container-sm:nth-child(n+2), .header .container-md:nth-child(n+2), .header .container-lg:nth-child(n+2), .header .container-xl:nth-child(n+2), .header .container-xxl:nth-child(n+2){
    min-height: unset;
  }

  .header-brand{
    padding:0px;
  }
  .container-top-nav{
    align-items: baseline;
  }
  .bm-burger-button {
    position: fixed;
    width: 26px;
    height: 17px;
    top: 24px;
    left: 21px;
  }
}
.custom-arrows {
  display: flex;
  gap: 10px; /* Space between the buttons */
  position: absolute; /* Position them absolutely within the parent container */
  right: 160px; /* Adjust this value to move them further left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for centering */
  z-index: 1; /* Ensure the buttons are above the carousel */
}

.custom-arrow {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1; /* Ensure the buttons are above the carousel */
}
.custom-arrow.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.custom-arrow-left {
  left: 10;
}

.custom-arrow-right {
  right: 0;
  top: 5;
}

@media (max-width: 768px) {
  .social-media-icons {
    display: none;
  }
  .custom-arrows {
    display: flex;
    gap: 0;
    position: relative !important;
    justify-content: end;
    right: 0;
    top: 0;
    transform: 0;
    align-items: end;
    margin-right: 25%;
    place-items: end;
  }
}
.social-media-mobile {
  display: flex;
  flex-wrap: wrap;
}
.social-media-icons-mobile {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
  color: white;
  /* position: absolute; */
  bottom: 20px;
  width: auto;
  padding: 0 5%;
  flex-wrap: wrap;
}

.social-media-icons-mobile .CNavItem {
  color: white;
}

.social-media-icon-wrapper {
  display: flex;
  align-items: center;
}

.social-media-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.formvalidation {
  color: #000;
  text-align: center;
}

.userinfo {
  position: relative;
  top: 9px;
}

.rdt_TableHeader {
  background: #3c4b64 !important;
  color: white !important;
}

.nav-panel {
  color: white !important;
}

.nav-panel h2 {
  font-weight: 400;
  font-size: 22px;
}

.rdt_TableCell {
  font-size: 14px !important;
}

.rdt_TableHead {
  font-size: 15px !important;
}

.add-icon {
  color: #3c4b64 !important;
  cursor: pointer !important;
}

.messageInfo {
  color: green;
}

.messageInfoError {
  color: red;
}

input#checkboxoptions {
  margin-left: 12px !important;
  border: 1px solid #3c4b64 !important;
  cursor: pointer !important;
}

.showcursor {
  cursor: pointer !important;
}

.iWyFZZ {
  white-space: inherit !important;
}

.remove-text-decoration {
  text-decoration: none !important;
  color: black !important;
}

.btn-metaverse {
  color: black !important;
  border: 1px solid black !important;
  border-radius: inherit !important;
  font-size: 15px !important;
}

.btn-metaverse:hover {
  color: white !important;
  border: 1px solid black !important;
  background: black !important;
  border-radius: inherit !important;
}

.bg-color {
  padding-top: 6% !important;
  padding-bottom: 6% !important;
  background: #f9f9f9 !important;
}

.banner-img {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bg-image {
  width: 100% !important;
  height: auto !important; /* Allow height to scale with aspect ratio */
  max-height: 500px !important;
  object-fit: cover; /* Maintain aspect ratio */
  aspect-ratio: 1920/1080; /* Set aspect ratio for 1920x1080 images */
}

@media (max-width: 768px) {
  .bg-image {
    height: 350px !important;
    object-fit: cover;
    aspect-ratio: 1920/1080; /* Maintain aspect ratio on mobile */
  }
}

.custom-user-dropdown {
  /* position: relative; */
  /* display: inline-block; */
  /* width: 200px; */
  width: 100%;
}

.custom-dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  border: none;
  color: #fff;
  text-align: left;
  width: 100%;
}

.custom-dropdown-toggle img,
.custom-dropdown-toggle .avatar {
  margin-right: 10px;
}

.custom-dropdown-toggle:hover {
  background-color: #444;
}

.custom-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
  width: 100%; /* Matches the width of the parent element */
  min-width: 200px; /* Ensures there's a minimum width */
}

.custom-user-dropdown:hover .custom-dropdown-menu {
  display: block;
}

.custom-dropdown-item {
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  /* display: flex; */
  align-items: center;
}

.custom-dropdown-item:hover {
  /* background-color: #444; */
}

.custom-dropdown-item svg {
  margin-right: 10px;
}

.custom-dropdown-avatar {
  background-color: #5a67d8; /* Custom background color for the avatar */
  border-radius: 50%;
  padding: 5px;
}

.cart-img-top {
  border-radius: inherit !important;
}

.card-img-overlay.overlay-img {
  padding-top: 10% !important;
  padding-left: 10% !important;
}

.icon-view {
  padding-top: 5% !important;
  padding-left: 15% !important;
}

.banner-icon {
  cursor: pointer !important;
}

.icon-align {
  float: right !important;
  /* padding-right: 80px !important; */
}

.icon-align1 {
  float: right !important;
}

.trending-color {
  padding-top: 1.5% !important;
  padding-bottom: 1% !important;
  background: #fff !important;
  display: flex;
}

.bg-img {
  width: 100% !important;
  max-height: 250px !important;
}

.bookmarks::before {
  border-top: 1px solid #000;
  content: "";
  display: inline-block;
  margin-left: 5px;
  margin-top: -3px;
  position: relative;
  vertical-align: middle;
  width: 20px;
}

.bookmarks1::before {
  border-top: 1px solid #000;
  content: "";
  display: inline-block;
  margin-top: -3px;
  position: relative;
  vertical-align: middle;
  width: 20px;
  margin-left: 15px;
}

.logo-image {
  width: 100% !important;
  height: 55px !important;
}

.react-player video {
  position: absolute !important;
  width: 80% !important;
  height: auto !important;
  border: var(--cui-border-width) solid
    var(--cui-thumbnail-border-color, var(--cui-border-color));
  border-radius: var(--cui-border-radius);
  padding: 0.25rem;
  background-color: var(--cui-thumbnail-bg, var(--cui-body-bg));
}

.user-video video {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.href-class {
  cursor: pointer !important;
}

#modal-popup {
  text-align: center !important;
  font-weight: 600;
  color: #000;
  position: absolute;
  top: 35px;
  z-index: 200;
  left: 29%;
  font-size: 30px;
}

.login-popup-text {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically (if needed) */
  z-index: 200;
  font-size: 30px;
  font-weight: 600;
  color: #000;
  text-align: center; /* Ensure the text itself is centered */
}
.modal-body {
  background: #f9f9f9 !important;
  /* padding-top: 0 !important; */
}

.modal-body.modal-body.front {
  background: #fff !important;
}

.modal-forgot {
  position: absolute !important;
  left: 0 !important;
}

.seperator {
  width: 100%;
  margin: 20px 0px;
  border-top: 1px solid #d8d8d8;
  position: relative;
}

.seperator h6 {
  position: absolute;
  top: -9px;
  left: calc(50% - 15px);
  background: #fff;
  padding: 0 5px;
}

.social-handle {
  text-align: center !important;
}

.social-icons {
  display: flex;
  border: 1px solid #00000054;
  margin: 0px 37px 0 37px;
  text-align: -webkit-center;
  margin-bottom: 16px;
}

.social-icons-mobile {
  display: flex;
  border: 1px solid #00000054;
  margin: 0px 24px 0 24px;
  text-align: -webkit-center;
  margin-bottom: 16px;
}

.social-icons-mobile .S9gUrf-YoZ4jf {
  position: absolute !important;
  left: 21% !important;
}

.google-icon {
  cursor: pointer !important;
}

.card.front-style {
  border: none !important;
  width: 350px !important;
  margin-top: 10px;
}
.card-footer {
  background-color: white !important;
}
.grid-three-card {
  width: 305px;
  margin-bottom: 5px;
}
.card-footer {
  border: none !important;
  background-color: white !important;
}

@media (min-width: 1023px) and (max-width: 1399px) {
  .card-img-top {
    width: 300px !important;
    height: 300px !important;
  }

  .card.front-style {
    width: 300px !important;
  }
}


.card-img-top {
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important;
  width: 350px;
  height: 350px;
  object-fit: cover;
}
.grid-three-img-card {
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important;
  object-fit: cover;
}
.image-front {
  /* background: #141414; */
  object-fit: cover;
  /* border-radius: 6px; */
}

.grid-three-row {
  margin-left: -10px; /* Adjust for reducing space between columns */
  margin-right: -10px;
}

.grid-three-card {
  margin-bottom: 10px !important; /* Reduce vertical space between cards */
  padding: 5px; /* Adjust padding for cards */
}

.grid-three-img-card {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 5px;
  border-radius: 8px;
}

.grid-three-card-body {
  padding: 10px 15px; /* Adjust padding inside the card body */
}

.grid-three-card-footer {
  padding-top: 5px;
  padding-bottom: 5px;
}

.image-flex-card {
  border-radius: none !important;
}
.image-front-girls {
  border: 0.1px solid black !important;
  border-radius: none !important;
  width: 100%;
}

.image-front-girls.sponsor {
  /* border: 0.1px solid black !important; */
}

.image-front .digital-issue {
  border-radius: 6px !important;
}

.discount {
  color: #b91a0b;
  font-weight: 300;
}

.discount-price{
  font-weight: 600;
}

.price-text{
  font-weight: 400;
}
.original-price {
  color: #000;
  font-weight: 400;
  text-decoration: line-through;
}

.sponsored {
  position: absolute !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  background: wheat !important;
  padding: 3px 13px 3px 13px !important;
  top: -55px; /* Adjust this value to position it vertically */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Correct the horizontal centering */
}

/* .image-front video {
    border-radius: 6px !important;
    height: 225px !important;
} */

/* .inner-container {
  padding-left: 6% !important;
  padding-right: 6% !important;
} */

.img-round {
  border-radius: 2px !important;
}

.player-wrapper {
  width: auto;
  height: auto;
}

.react-player {
  position: initial;
}

.react-player > video {
  position: absolute;
  object-fit: fill;
}

.react-player > div {
  position: absolute;
}

.bg-clor {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 4% !important;
  padding-bottom: 2% !important;
}

.digital-front {
  border-radius: 0 !important;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);  */
}

.digital-front:hover {
  cursor: pointer !important;
  /* -moz-transform: scale(01.02); */
  /* -webkit-transform: scale(01.02); */
  /* transform: scale(01.02); */
}

.digital-front-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 305px;
  padding: 0.5rem;
  z-index: 1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  transition: transform 0.3s ease;
  height: 75px;
}

.digital-card:hover .digital-front-title.with-subtitle {
  transform: translateY(0px); /* Move the title up only if subtitle exists */
  transition: transform 0.9s ease-in, opacity 0.9s ease-in;
}

.digital-card-title {
  margin: 2px;
  text-transform: uppercase;
  font-family: "Renner";
  font-size: 1.5rem;
  line-height: 1.8rem;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); Gradient overlay */
}
.digital-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  width: 305px;
  flex: 0 0 auto;
  margin: 0;
}
.digital-card {
  flex: 0 0 auto;
  width: 305px;
  margin: 0; /* Ensure no additional margin is added */
}

.digital-card:hover .digital-front-title p.subtitle {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
  font-family: "Renner";
  font-size: 1rem;
  height: auto; /* Ensure height is auto on hover */
  margin-top: -5px; /* Add some space between title and subtitle on hover */
  color: #fff; /* Change subtitle color to white on hover */
}

.subtitle {
  transform: translateY(20px); /* Initially move subtitle down */
  transition: transform 0.5s ease-in-out; /* Smooth transition for the subtitle */
  font-family: "Renner";
  /* font-size: 1rem; */
  /* color: #fff; */
}

.digital-front-title p.subtitle {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
  height: 0; /* Hide the subtitle initially */
  margin: 0; /* Remove any default margin */
  overflow: hidden; /* Hide overflow to ensure subtitle is hidden initially */
}

.digital-front-title p {
  margin: 0;
}

.digital-card-title {
  margin-bottom: 0; /* Remove bottom margin to avoid extra space */
}

.bg-trends {
  background: #f9f9f9 !important;
}

.trends-row {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.innerpage-trends-row {
  padding-right: 0px !important;
  padding-left: 24px !important;
}


.innerpage-trends-row:after {
  margin-top: 24px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 24px;
}

.innerpage-trends-row:last-child::after {
  margin-top: 24px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.innerpage-trends-row:after {
  border-top: 1px solid #e3e3e3;
  content: "";
  display: inline-block;
  width: 100%;
}

.innerpage-trends-row:last-child::after {
  border-top: none;
  content: "";
  display: inline-block;
  width: 100%;
}


.trends-card {
  border: 0;
}

.trends-row:after {
  margin-top: 24px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 24px;
}

.trends-row:last-child::after {
  margin-top: 24px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.trends-row:after {
  border-top: 1px solid #e3e3e3;
  content: "";
  display: inline-block;
  width: 100%;
}

.trends-row:last-child::after {
  border-top: none;
  content: "";
  display: inline-block;
  width: 100%;
}

.website-first-part {
  float: right !important;
  padding-right: 28px !important;
  border-right: 2px solid white !important;
}

.website-first-part h1 {
  margin-top: -19px;
  margin-left: -29px;
}


.web-style {
  padding-top: 2%;
}

.overlay-loader{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

}

@media (max-width: 767px) {
  .web-style {
    padding-top: 4%;
  }
  .btn-web{
    padding-left:8px !important;
    padding-right:8px !important;
    padding-top:4px;
    padding-bottom:4px;
  }
}

.btn-web {
  background: #000;
  color: white;
  border: none;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.btn-web:hover {
  background: #000;
  color: white;
  border: none;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
  border: 1px solid #000;
}

.footer-ui {
  background: #000;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-left: 7%;
  padding-right: 7%;
  position: relative;
  width: 100%;
  bottom: 0;
}

/* remove padding from footer-ui in mobile view */
@media (max-width: 767px) {
  .footer-ui {
    padding:20px;
    min-height: 195px;
  }
}

.footer__social {
  display: flex !important;
  justify-content: flex-start !important;
}

.web-second {
  float: right;
}

.web-second p {
  font-size: 13px;
}

/* Contact.diverseland@gmail.com
DLupandtop#1 */

.web-desc p {
  margin-top: 15px;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.footer-second:after {
  border-top: 1px solid #e3e3e3;
  content: "";
  display: inline-block;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.web-desc-second ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.web-desc-second ul li {
  font-weight: 500;
  padding-right: 8px;
  font-size: 15px;
}

@media (max-width: 767px) {
  .web-desc-second ul {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: flex-start; */
    gap: 10px;
  }

  .web-desc-second ul li {
    /* flex: 0 0; */
    padding-right: 0;
    font-size: 12px;
    text-align: left;
  }

  .mobile-footer-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important; /* 4 columns on mobile */
    gap: 10px;
  }

  .mobile-footer-grid li {
    font-size: 12px;
    text-align: left;
  }
}

/* For larger screens */
@media (min-width: 768px) {
  .web-desc-second ul {
    width: 100%;
    max-width: 1200px; /* Maximum width for larger screens */
    margin: 0 auto;
  }
}

.web-desc-third p {
  text-align: center;
  font-size: 0.8rem;
  padding-top: 5px;
}

.bg-product-color {
  padding-top: 4% !important;
  padding-bottom: 4% !important;
  background: #f9f9f9 !important;
}

.btn-web-cart {
  background: #000;
  color: white;
  border: none;
  padding-left: 8%;
  padding-right: 8%;

  padding-top: 3.5%;
  /* padding-bottom: 3%;    */
}

.btn-web-cart:hover {
  background: #000;
  border: 1px solid #000;
  font-weight: 500;
  color: white;
  border: none;
  padding-left: 8%;
  padding-right: 8%;
}

.btn-web-cart:focus {
  background: #000;
  border: 1px solid #000;
  font-weight: 500;
  color: white;
  border: none;
  padding-left: 8%;
  padding-right: 8%;
}

.btn-web-cart:active {
  background: #000;
  border: 1px solid #000;
  font-weight: 500;
  color: white;
  border: none;
  padding-left: 8%;
  padding-right: 8%;
}

.btn-web-cart:visited {
  background: #000;
  border: 1px solid #000;
  font-weight: 500;
  color: white;
  border: none;
  padding-left: 8%;
  padding-right: 8%;
}

.product-data {
  text-align: center;
  background: #f9f9f9 !important;
}

.product-data h5 {
  color: #000;
}

.product-data .price {
  font-size: 18px !important;
  color: #000 !important;
  font-weight: 700 !important;
}

/*.product-data .card-text button {
    text-align: center !important;
} */

.product-data .product-card {
  background: transparent !important;
  border: none !important;
}

/* @media only screen and (max-width: 600px) {
  .product-card {
    flex-direction: column; 
  }

  .image-container {
    margin-right: 0; 
    margin-bottom: 16px;
    width: 100%; 
  }
} */

.heading {
  color: #1b1b1b !important;
  letter-spacing: 10px;
  font-weight: 400 !important;
  font-family: "Helvetica", sans-serif !important;
  font-size: 26px !important;
}

.login-info {
  display: flex;
  align-items: center;
}

.user-title {
  margin-top: 9%;
}

/* .user-title-manual {
  margin-top: 3%;
} */

.sidebar-toggler::before {
  display: none;
}

.img-thumbnail {
  max-height: 100px !important;
}

.row.bottom-row {
  margin-top: 20px !important;
}

.marquee-class {
  display: flex !important;
}

#narrow {
  float: right;
  width: 200px;
  color: #000;
  font-size: 18px;
  /* font-weight: 800; */
  text-align: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#wide {
  float: left;
  width: calc(100% - 200px);
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

@media (max-width: 767px) {
  #narrow {
    float: none;
    width: auto;
    text-align: left;
    font-size: 14px;
    border-radius: 40px;
    background-color: rgb(231, 231, 231);
  }

  #narrow img {
    height: 38px;
  }
  #wide {
    float: left;
    width: calc(100% - 116px);
    /* margin-top: 10px; Add spacing if needed */
  }

  .marquee-class {
    display: block;
    width: 100%;
    border: 2px solid rgb(231, 231, 231);
    border-radius: 35px;
    margin: 0px;
  }

  .marquee-item {
    font-size: 14px;
    margin-right: -1px;
    padding: 5px;
  }

  .trending-color {
    padding: 12px; /* Adjust padding for mobile view */
    border-radius: 25px; /* Ensure border radius is applied */
  }

  .shop-button {
    font-weight: bold !important;
    letter-spacing: 1px !important;
    margin-top: 10px !important;
    max-width: inherit !important;
    background: #000 !important;
    color: #fff !important;
    padding: 15px !important;
    width: auto !important;
    /* width: 100% !important; */
  }
  .mobile-container{
    display: none;
  }
}

.mobile-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.mobile-flex-container .foot-desc {
  margin-bottom: 10px;
}

.mobile-flex-container .btn-web {
  align-self: flex-start;
}

.marquee img.avatar-img {
  background-color: #fff;
  border-radius: 50%;
  height: 1.5rem;
  margin-bottom: 0.5rem;
  width: 1.5rem;
  padding: 0.0625rem;
}


.bg-color-ads {
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  background: #f9f9f9 !important;
}

/* for mobile, remove the color */
@media (max-width: 767px) {
  .bg-color-ads {
    background: transparent !important;
  }
}

/* .register{
    padding: 45px 0px 31px 19px !important;
} */
.profile-bg {
  background: #f9f9f9 !important;
  padding-top: 2%;
  padding-left: 3%;
  padding-bottom: 2%;
  padding-right: 3%;
}

.profile-space {
  background: #fff !important;
  padding: 3% 2% 5% 2%;
}

#profile {
  border-right: 2px solid #e4dede !important;
}

.profile-icon {
  text-align: center !important;
}

.profile-icon-two {
  text-align: left !important;
  margin-top: 12%;
  margin-left: 5%;
}

.profile-icon-two ul {
  margin-bottom: 20px;
  display: flex;
}

.profile-icon-two ul a:hover {
  color: #000;
}

.a-active {
  color: #000 !important;
}

.profile-icon-two ul a:hover svg.icon.icn-view {
  visibility: visible !important;
}

.profile-icon h2 {
  margin-top: 10px !important;
}

#profile .avatar {
  width: 8rem !important;
  height: 8rem !important;
  font-size: 2.8rem !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.profile-icon-two ul a:-webkit-any-link {
  text-decoration: none;
  color: #ccc;
  cursor: pointer;
}

a:-webkit-any-link {
  text-decoration: none;
}

svg.icon.icn-view-active {
  visibility: visible;
  margin-right: 10%;
  float: right;
}

svg.icon.icn-view {
  visibility: hidden;
  margin-right: 10%;
  float: right;
}

.show-drop {
  background: #fff;
  border: 1px solid #f6f6f6;
  left: 50%;
  padding: 24px 0 16px;
  line-height: 1.5;
  position: absolute;
  text-align: left;
  top: 100%;
  transform: translate3d(-50%, 0, 0);
  width: auto;
  border-radius: 0;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.show-drop li {
  white-space: nowrap;
  /* text-align: center; */
}

.left-none {
  left: none !important;
}

.text-align-center {
  text-align: center;
}

.menu-item {
  /* display: flex !important; */
  padding: 0px 0; /* Adjust padding as necessary */
}
.menu-item .dropdown-menu[data-coreui-popper].left-none {
  position: relative;
  background: transparent;
  border: none;
  text-align: center !important;
  padding-top: 0px;
  left: none !important; /* Ensure this is overriding */
}

.sub-header-menu {
  cursor: pointer !important;
}

.nav-item {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Renner" !important;
  letter-spacing: 0.04rem !important;
}

.header.header-sticky {
  box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.3);
}

.nav-item a {
  color: #000 !important;
  /* text-transform: uppercase !important; */
  letter-spacing: 1px !important;
  /* font-weight: 500 !important; */
  font-family: "Renner" !important;
  font-size: inherit;
}

#wide .avatar {
  position: relative !important;
  left: 10px !important;
  display: inline-flex !important;
  align-items: normal !important;
  justify-content: center !important;
  vertical-align: middle !important;
  border-radius: 50em !important;
  transition: margin 0.15s !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  font-size: 0.8rem !important;
  /* bottom:2px; */
}


/* mobile media query */
@media (min-width: 767px) {
  #wide .avatar {
    /* bottom: 0.039cm; */
  }
  .container-top-nav{
  align-content: center;
  }
 
}
/* .header-dropdown a.nav-link.sub-header-menu:after{
  display: inline-block !important
} */
.marquee-item {
  margin-top: 0px;
  font-size: 15px;
}

/* add mobile media query, to show the search icon on mobile */
@media (max-width: 767px) {

  .search-mobile-icon {
    display: block;
  }
}

 
.sidebar-nav .nav-item a {
  color: #9da5b1 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  cursor: pointer !important;
  /* color: #321fdb !important; */
  color: #5e5e5e !important;
  /* font-weight: 700 !important; */
  background: none !important;
}

.sub-header-menu:after {
  /* background: #f5b000 !important; */
  background: #000 !important;
  bottom: 0em !important;
  content: "" !important;
  display: none;
  height: 2.5px !important;
  left: 50% !important;
  position: absolute !important;
  transform: translate3d(-50%, 0, 0) !important;
  width: calc(100% - 2em) !important;
}

.header-dropdown:hover .sub-header-menu::after {
  display: block !important;
  position: relative;
  z-index: 2000;
}

.header-dropdown:hover .show-drop {
  display: inline-block !important;
}

.show-drop {
  display: none !important;
}

ul#subheader li a {
  font-size: 14px !important;
  color: black;
  font-weight: 300 !important;
}

.showcursor-fa {
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  margin-left: 25px;
}

.showcursor-fa-icon {
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  margin-left: 10px;
}

.show-ads {
  /* position: fixed !important; */
  left: 0 !important;
  top: auto !important;
  right: 0 !important;
  z-index: 1029 !important;
}

.no-data {
  padding: 100px;
  text-align: center;
  font-size: x-large;
  font-weight: 700;
}

.page-title {
  font-size: 3rem;
  color: #000;
}

.page-img-title {
  font-size: 3rem;
  color: #fff;
}

.page-para {
  color: #fff;
}

.background-img {
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.border-line {
  display: flex;
  height: 5px !important;
  width: 100% !important;
  background-color: #000;
  border-radius: 100px;
  margin: 0 0px;
}

.border-white-line {
  display: flex;
  height: 5px !important;
  width: 100% !important;
  background-color: #fff;
  border-radius: 100px;
  margin: 0 0px;
  z-index: 2000;
}

.grid-four {
  border: none;
  /* background-color: transparent !important; */
}

.grid-four.bg-clr {
  background: transparent !important;
}

.grid-four .card-body {
  background-color: #fff !important;
}

.trends-ads img {
  height: 600px !important;
  width: 300px !important;
}
.trends-ads {
  width: 100%; /* Ensures the container maintains its width */
}

.ad-image {
  width: 100%;
  height: 160px; /* Fixed height to prevent resizing */
  object-fit: cover;
}

/* .trends-ads-wrapper {
  position: relative;
}

.sticky-ads {
  position: fixed;
  top: 20px;
  z-index: 1000;
  width: inherit;
} */

.card {
  border: none !important;
  border-radius: inherit !important;
}

.card-title {
  color: #000 !important;
  line-height: 1.3 !important;
  letter-spacing: 0.04rem !important;
  font-weight: 500 !important;
  font-family: "Renner";
  word-break: break-word !important;
}

.text-medium-emphasis strong {
  font-weight: 400 !important;
  font-family: "Renner";
  color: black;
  font-size: 0.6875rem;
}

h5.card-title h1 {
  color: #fff !important;
}

h1.heading-color {
  color: #000 !important;
}

.img-size {
  max-width: 450px !important;
  margin: 0 auto !important;
}

/* .img-detail {
  margin-top: 7% !important;
} */

.img-detail img {
  max-width: 100% !important;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .img-detail p span img {
    max-width: 350px !important;
    margin: unset;
    width: auto;
    height: auto;
  }

  .img-detail p img {
    max-width: 350px !important;
    margin: 0 auto;
    height: auto;
  }

  .center-cards {
    margin: 0px auto !important;
  }

  .img-detail p span .shop-button {
    max-width: 350px !important;
    width: 100% !important;
  }
  .img-detail p span a {
    max-width: 350px !important;
    margin: unset;
    width: auto !important;
    height: auto !important;
    /* display: auto; */
    justify-content: center;
  }


  .img-detail p a span {
    max-width: 350px !important;
  }

  .img-detail ol li p span {
    max-width: 350px !important;
    margin: unset;
    width: auto !important;
    height: auto !important;
    display: flex;
    justify-content: unset;
  }
  .img-detail p {
    margin: 0 !important;
    text-align: left;
  }
  .img-detail ul {
    width: fit-content !important;
  }
  .img-detail p span strong {
    font-size: 26px !important;
  }
  .img-detail p span span {
    font-size: 16px !important;
  }
  .img-detail p .shop-button {
    font-weight: 400 !important;
  }
  #ads-view{
    margin-top:15px;
    margin-bottom: 15px;
  }
  .trends-ads-mobile{
    margin-top:150px;
    margin-bottom: 150px;
    margin-left:10px;
    margin-right:10px;
    background: transparent;
  }
  .trends-ads-mobile img{
    min-width:220px !important;
    height: 280px !important;
  }
  .ad-image{
    width: 300px !important;
    height: 250px !important;
  }

  .footer-row {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-bottom: 1rem !important;
    justify-content: space-between !important;
    gap: 1rem !important;
    width: 100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .footer-items {
    padding-left: 0 !important;
  }
}

.se-image-container {
  max-width: 450px;
  margin: 0 auto !important;
}

.card.inner-details {
  border: none;
  /* max-width: 450px; */
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.button-group--product {
  align-items: flex-start;
  display: flex;
}

.button--shop {
  font-size: 1rem;
  font-weight: 700;
  height: auto;
  padding: 0.7em 1.9em 0.4em;
  text-transform: uppercase;
  width: 33.5rem !important;
}

.product-favorite-button {
  margin-left: 6px;
  background-color: #fff;
  border: 1px solid #000;
  height: 43px;
  width: 50px;
  cursor: pointer;
}

.inner-icon {
  position: relative;
  left: 4px;
  top: 4px;
  background: transparent;
}

.btn-dark {
  background: #000015 !important;
  color: #fff !important;
  font-size: 15px !important;
}

img.card-img {
  border-radius: 0 !important;
}

.nav-panel h2 {
  margin-top: 12px !important;
}

.footer-type {
  position: absolute;
  right: 90px;
}

.pagination {
  --cui-pagination-active-color: rgba(255, 255, 255, 0.87);
  --cui-pagination-active-bg: #000 !important;
  --cui-pagination-active-border-color: #000 !important;
  --cui-pagination-hover-color: #000 !important;
  --cui-pagination-color: #000 !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.disabled > .page-link {
  color: #000 !important;
  pointer-events: none;
  background-color: #fff !important;
}

.modal-content {
  border: none !important;
  border-radius: 0 !important;
}

.btn-close {
  --cui-btn-close-color: #000 !important;
  --cui-btn-close-opacity: none !important;
  --cui-btn-close-hover-opacity: none !important;
  --cui-btn-close-focus-shadow: #000 !important;
  --cui-btn-close-focus-opacity: 0 !important;
  --cui-btn-close-disabled-opacity: 0 !important;
}

.model-head {
  border-bottom: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.model-foot {
  border-bottom: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.bottom-btn {
  position: relative;
  top: -17px;
  border-radius: initial;
  background: #000;
  color: #fff !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-color: #000 !important;
}

.bottom-btn:hover {
  position: relative;
  top: -17px;
  border-radius: initial;
  background: #000;
  color: #fff !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-color: #000 !important;
  opacity: 0.8 !important;
}

.bottom-btn:active,
.bottom-btn:focus-visible {
  position: relative !important;
  top: -25px !important;
  border-radius: initial !important;
  background: #000 !important;
  color: #fff !important;
  box-shadow: none !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-color: #000 !important;
}

.text-medium-emphasis {
  text-align: center;
}

.card-category-text {
  font-family: "Renner";
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
}

label.form-label.login-form {
  margin-bottom: -10px;
  color: #000;
  font-weight: 500;
  margin-top: 6px;
}

ul.acc-info {
  font-size: 12px;
  /* margin-left: 5px; */
  /* margin: 0 auto; Center horizontally */
}

.already-acc {
  margin-top: 20px;
}

.form-control:focus {
  border-color: #000 !important;
  box-shadow: 0 0 0 0.1rem #000 !important;
}

.acc-info .sing-in {
  margin-left: 20px !important;
}

.captcha {
  margin-top: 9%;
  margin-left: 9%;
}

ul.acc-info.sing-in {
  margin: 0 auto; /* Center horizontally */
  width: fit-content; /* Ensure the width fits the content */
  /* text-align: center; Center text inside the list */
}

ul.acc-info.sing-up {
  width: fit-content; /* Ensure the width fits the content */
  /* text-align: center; Center text inside the list */
}

.mobile-header {
  font-weight: bolder !important;
  color: #000 !important;
}

.sidebar-narrow-unfoldable {
  position: fixed;
  z-index: 1031;
  background: #000 !important;
}

input .form-control {
  border-radius: none !important;
}

.sidebar.sidebar-fixed.sidebar-narrow-unfoldable.show {
  width: 85% !important;
}

.admin-nav .show .nav-item a {
  float: left !important;
  background: transparent !important;
}

.show .nav-item a {
  float: right !important;
  background: transparent !important;
}

.mobile-sidebar .nav-group-toggle {
  cursor: pointer;
  text-transform: uppercase;
}

.overlay-img-mobile {
  position: relative;
  background: #f9f9f9 !important;
  text-align: center;
  font-size: smaller;
  color: #000;
  border: none !important;
  border-radius: 0 !important;
}

.overlay-img-mobile h5.card-title h1 {
  color: #000 !important;
  padding-top: 10px !important;
  font-size: 19px;
  font-weight: 600;
}

.overlay-img-mobile h5 {
  font-size: 0.9rem;
}

.header-toggler {
  padding: 0 !important;
  color: #000 !important;
  border: none !important;
}

ul.sidebar-nav.mobile-sidebar .nav-item {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 11px;
  color: #fff;
}

.website-first-part-mobile {
  text-align: center;
}

.website-first-part-mobile p {
  font-size: larger;
}

.website-first-part-mobile h1 {
  position: relative;
  top: -19px;
}

.foot-desc {
  position: relative;
  top: -23px;
}

.marquee-class {
  width: 100% !important;
}

div#mobilesidebar {
  background: #000 !important;
}

.sidebar-backdrop {
  display: none !important;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background-color: #ffffff0d;
  overflow-x: hidden;
  transition: 0.5s;
}

.mobile-sidebar {
  padding-top: 100px;
}

.bm-menu {
  overflow: hidden !important;
}
.mobile-sidebar ul {
  display: inline-block;
  list-style-type: none;
  position: relative;
  left: -21px;
}

.mobile-sidebar ul li a {
  color: #fff !important;
}

.mobile-sidebar ul li {
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 0.079em;
  padding: 0.3em 0px;
  /* text-align: center; */
  text-transform: uppercase;
}

.mobile-sidebar ul li a::after {
  display: inline-flex;
  right: 20px;
  width: 0.875rem;
  height: 0.875rem;
  padding: 0;
  font-size: 1.975rem;
  line-height: 0.65625rem;
  text-align: center;
  content: "\2039";
  transition: 0.3s;
  position: relative;
  float: right;
}

.mobile-sidebar ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.mobile-sidebar ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

.mobile-sticky-ad {
  transition: transform 0.3s ease-in-out;
}

.mobile-sticky-ad.show {
  transform: translateY(0);
}

.mobile-sticky-ad:not(.show) {
  transform: translateY(100%);
}
.icon-white {
  color: #fff !important;
  position: absolute;
  top: 25px;
  right: 36px;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  padding-left: 20px;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 17px;
  top: 18px;
  left: 21px;
}

@media (max-width: 768px) {
.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 17px;
    top: 24px;
    left: 21px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px !important;
  width: 30px !important;
  position: absolute !important;
  right: 97px !important;
  display: grid;
  top: 8px !important;
}
/* Color/shape of close button cross */
.bm-cross {
  width: 80px !important;
  height: 32px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #000 !important;
  padding: 2.5em 0 0 !important;
  /* padding: 2.5em 1.5em 0 !important; */
  font-size: 1.15em !important;
  left: 0px !important;
  width: 85% !important;
  height: 100%;
  transition: all 0.5s ease 0s !important;
  top: 0 !important;
  overflow-y: auto;
}

.bm-menu-wrap {
  width: 100% !important;
  left: 0;
  top: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  margin-top: 20px;
  position: relative;
  overflow-y: auto;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(0 0 0 / 21%) !important;
  backdrop-filter: blur(6px);
  top: 0;
  left: 0;
}

.menu-item p:after {
  margin-left: auto;
}

/* .show p:after {
  transform: rotate(180deg) !important;
} */

.show .dropdown-arrow-custom{
  transform: rotate(180deg) !important;
}
/* 
.menu-item p:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgMjU2Ij48cmVjdCB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMjE1LjM5LDkyLjk0QTgsOCwwLDAsMCwyMDgsODhINDhhOCw4LDAsMCwwLTUuNjYsMTMuNjZsODAsODBhOCw4LDAsMCwwLDExLjMyLDBsODAtODBBOCw4LDAsMCwwLDIxNS4zOSw5Mi45NFoiIGZpbGw9IiNGRkZGRkYiLz48L3N2Zz4=");

  background-position: 50%;
  background-repeat: no-repeat;
  content: "";
  display: block;
  flex: 0 1 12px;
  height: 28.5px;
  transition: transform 0.15s;
  position: relative;
  top: -34px;
  left: 6px;
} */
/* 
.menu-item.no-dropdown p:after {
  content: none; 
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: none;
  content: "";
  display: block;
  flex: 0 1 12px;
  height: 28.5px;
  transition: transform 0.15s;
  position: relative;
  top: -34px;
  left: 6px;
} */

.menu-cross-button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48bGluZSB4MT0iMTgiIHkxPSI2IiB4Mj0iNiIgeTI9IjE4Ij48L2xpbmU+PGxpbmUgeDE9IjYiIHkxPSI2IiB4Mj0iMTgiIHkyPSIxOCI+PC9saW5lPjwvc3ZnPg==");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other menu items */
}
.menu-item .show {
  background: var(--cui-sidebar-nav-group-bg);
}
.dropdown-arrow-custom {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgMjU2Ij48cmVjdCB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMjE1LjM5LDkyLjk0QTgsOCwwLDAsMCwyMDgsODhINDhhOCw4LDAsMCwwLTUuNjYsMTMuNjZsODAsODBhOCw4LDAsMCwwLDExLjMyLDBsODAtODBBOCw4LDAsMCwwLDIxNS4zOSw5Mi45NFoiIGZpbGw9IiNGRkZGRkYiLz48L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  /* width: 18px; Adjust based on arrow size */
  width: 21.5px; /* Adjust based on arrow size */
  height: 21.5px; /* Adjust based on arrow size */
  margin-left: auto; /* Push the arrow to the right */
  transition: transform 0.3s;
  position: relative;
  right:10px;
  bottom:17px;
}

.menu-item .dropdown-menu[data-coreui-popper] {
  position: relative;
  /* margin-left: 80px; */
  background: transparent;
  border: none;
  text-align: center !important;
  padding-top: 0px;
  left: none !important;
}



.menu-item:not(.show) .show-drop {
  display: none;
}

.show .show-drop {
  display: block !important;
}

.menu-item ul#subheader li a {
  font-size: 21px !important;
  color: #fff;
}

.menu-item ul#subheader li a:hover {
  font-size: 21px !important;
  color: #fff !important;
  font-weight: bold !important;
}

.bm-item.menu-item.show p {
  font-weight: bolder;
  color: #ffffff69;
}

.menu-item .dropdown-menu[data-coreui-popper] {
  position: relative;
  /* margin-left: 80px; */
  background: transparent;
  border: none;
  text-align: center !important;
  padding-top: 0px;
  left: none !important;
}

.bm-item.menu-item p {
  font-size: 25px;
  margin-bottom: -16px;
}

.bm-item.menu-item.show {
  background: #000;
}
.menu-item p {
  margin: 0;
  font-size: 16px;
}

/* li.nav-item.dropdown.mobile-signout {
    position: relative;
    top: -46px;
} */

.web-second-mobile {
  margin-top: 35px;
  text-align: center;
}

.captcha-mobile {
  margin-top: 9%;
  margin-left: -12%;
}

/* img.n1UuX-DkfjY {
    display: none !important;
} */

/* .nsm7Bb-HzV7m-LgbsSe-BPrWId {
    display: none;
} */

.social-icons .S9gUrf-YoZ4jf {
  position: absolute !important;
  left: 30% !important;
}

.spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.8;
  z-index: 1000;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
}

a.black-color {
  color: black;
  font-weight: 500;
}

span#checktext {
  font-size: 13.5px;
  text-align: center;
}

span#checkbox {
  font-size: 14px;
  text-align: center;
}

span#checkbox {
  font-size: 14px;
  text-align: center;
  margin-top: -26px;
  padding: 10px;
}

.modal-header {
  border-bottom: none !important;
}

.bannerImg {
  margin-left: -50px;
}

.overlay-img p.card-text {
  color: white;
}

.product-data p.card-text {
  color: #000;
}

.nav-item a.nav-link.active {
  color: #fff !important;
}

.innerpage-card p.card-text {
  color: #000;
}

.detail-page p.card-text {
  color: #000;
}

.trending-card-image-cls{
  width: 350px !important;
  height: 350px !important;
}

@media (max-width: 767px) {
  .trending-card-image-cls {
    width: 100% !important;
    height: 350px !important;
  }
}


/* for laptop screens I want the top-ads-image to be 1000px in width */
@media (min-width: 990px) and (max-width: 1200px) {
  .top-ads-image {
    width: 100%;
  }
  .trending-card-image-cls{
    width: 257px !important;
    height: 257px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .top-ads-image {
    width: 100%;
  }
    .trending-card-image-cls{
    width: 257px !important;
    height: 257px !important;
  }
}

/* for ipads as well */
@media (min-width: 766px) and (max-width: 1124px) {
  .top-ads-image {
    width: 100%;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

ul.react-multi-carousel-track li {
  /* padding-right: 7px !important; */
  padding-right: 7px !important;
  padding-left: 12px !important;
}

.carousel-track {
  scroll-behavior: smooth;
}

.sidebar-nav .nav-group .nav-group-items {
  padding: 0px 50px 0px 0px !important;
}

/* .shop-button {
  font-weight: 500 !important;
  letter-spacing: 1px !important;
  margin-top: 10px !important;
  max-width: inherit !important;
  padding: 15px !important;
  width: 100% !important;
} */

.shop-button {
  font-weight: 400 !important;
  letter-spacing: 1px !important;
  margin-top: 10px !important;
  max-width: inherit !important;
  background: #0e0e0e !important;
  color: #fff !important;
  padding: 11px !important;
  width: 600px !important;
  height: 42px !important;
  font-family: 'Helvetica' !important;
  display:inline-block;
  /* width: 100% !important; */
}

.must-have-product-mobile {
  display: none;
}

.must-have-product-web {
  display: block;
}

/* add media query for mobile */
@media (max-width: 767px) {
.custom-card-product {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  /* background: #fff; */
  padding: 10px;
  height: 100%;
}

.custom-card-latest {
  display: flex;
  /* flex-direction: column; */
  margin-bottom: 15px;
  /* background: #fff; */
  padding: 10px;
  height: 100%;
}

.custom-card-latest-image-container {
  /* width: 100%; */
  margin-bottom: 10px;
  position: relative;
}



  .image-container {
    flex: 0 0 100px;
  }

.custom-card-product-image-container {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}


  .content-container {
    /* flex: 1; */
    /* margin-left: 15px; */
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
  }

  .text-content {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    margin:5.5px;
  }

  .product-title {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
  }

  .product-description {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
  }
  .latest-flex-card-title{
    font-size: 16px;
    margin: 0;
    font-weight: 400;
  }

  .price {
    font-size: 14px;
    color: black;
    margin: 5px 0 10px 0;
  }
  .latest-flex-card-desc {
    font-size: 13px;
    margin: 5px 0 10px 0;
    font-weight: normal;
    font-family: 'Helvetica';
    color:#000;
  }

  .button-container {
    text-align: left;
  }

  .sponsored {
    position: absolute !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    background: wheat !important;
    padding: 3px 13px 3px 13px !important;
    top: -46px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    
}

  .shop-button-product {
    font-weight: bold;
    letter-spacing: 1px;
    background: #000;
    color: #fff;
    padding: 10px;
    width: 100px;
    font-size: 12px;
    margin-top: 10px;
    display: block;
  }
  .shop-button {
    font-weight: bold !important;
    letter-spacing: 1px !important;
    margin-top: 10px !important;
    max-width: inherit !important;
    background: #000 !important;
    color: #fff !important;
    padding: 11px !important;
    width: 100% !important;
    font-size: 13px !important;
  }
  .img-detail span img {
    display: block;
    margin: 0;
    /* margin-left: auto; */
    /* margin-right: auto; */
    max-width: 100%; /* Ensure the images are responsive */
    height: auto; /* Maintain aspect ratio */
  }
  .must-have-product-mobile {
    display: contents;
    text-align: center;
    margin: 0 auto;
  }

  .must-have-product-web {
    display: none;
  }

  .product-card-container {
    flex-direction: row;
    margin-bottom: 15px;
  }

  .image-container {
    flex: 0 0 30%;
    max-width: 30%;
    margin-right: 15px;
  }

  
  .product-data {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .product-title,
  .product-description,
  .buy-now-container {
    text-align: center;
    margin: 0;
    font-family: "Renner";
    font-weight: 500;
    color: black;
  }

  .latest-flex-card-title{
    text-align: left;
    margin: 0;
    font-family: "Helvetica";
    font-weight: normal;
    color:#000;
  }

  .product-card {
    flex-direction: row;
  }
  .product-data {
    text-align: start;
    background: #f9f9f9 !important;
  }

  .image-front-girls {
    width: 171px;
    height: 171px;
    border: 0.1px solid #000 !important;
    object-fit: cover; /* Ensure the image fits within the specified dimensions */
  }
  .image-flex-card {
    width: 140px !important;
    border: 0px;
    height: 140px !important;
    object-fit: cover; /* Ensure the image fits within the specified dimensions */
  }
  .product-data .card-title {
    font-size: 16px;
  }

  .mobile-footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
  }

  .mobile-footer-grid li {
    font-size: 12px;
  }
}


.product-card{
  width: 265px !important;
}
/* add media query for desktop */

@media (min-width: 990px) and (max-width: 1200px) {
  .product-card {
    width: 225px !important;
  }
}

.jodit-workplace div:nth-child(3) {
  display: none !important;
}

.card .trend {
  width: 100% !important;
}

.trend .image-front {
  height: 360px !important;
  width: 100% !important;
}

.container .first-row {
  /* padding-right: 4%; */
  /* padding-left: 10%; */
  padding: 5%;
}

.container .second-row {
  padding-right: 2%;
  padding-left: 2%;
}

/* .row-padding{
  margin: 10px;
  margin-right: 10px;
} */

html:not([dir="rtl"]) .form-switch {
  padding-left: 0 !important;
}

html:not([dir="rtl"]) .form-check {
  padding-left: 0 !important;
}

html:not([dir="rtl"]) .form-switch .form-check-input {
  margin-left: -4.5em !important;
}

/* Update the footer items styling for mobile */
@media (max-width: 767px) {
  .footer-row {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important; /* Force 4 columns */
    gap: 1rem !important;
    width: 100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .footer-items {
    padding-left: 0 !important;
    list-style: none;
  }

  .footer-items li {
    font-size: 12px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Ensure each item takes up 25% of the width */
  .footer-items li {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .mobile-footer-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important; /* Force 4 columns */
    gap: 10px;
  }

  .mobile-footer-grid li {
    font-size: 12px;
    text-align: left;
  }
}

.jodit-container a {
  /* text-decoration: underline !important; */
  color: unset !important;
}

.jodit-container .shop-button {
  color: white !important;
  text-decoration: none !important;
}

.card-title:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.card-desc:hover {
  text-decoration: none !important;
}

.custom-card-title:hover {
  text-decoration: underline !important;
}